import axios from 'axios';
import config from '@/vue.config';

const GET_ALL_USER_TRANSACTIONS_URL =
    config.store.finances.getAllUserTransactions;
const GET_PDF_DETAILS_TRANSACTIONS_URL = config.store.finances.getPdfDetails;
const GET_PDF_INVOICE_TRANSACTIONS_URL = config.store.startup.getPdfInvoice;
const GET_ALL_TRANSACTION_EXCEL = config.store.finances.getAllExcel;

const state = {
    transactions: [],
    totalTransaction: 0,
};

const getters = {
    getTransactions: state => {
        return state.transactions;
    },
    getTotalTransactions: state => {
        return state.totalTransaction;
    },
};

const mutations = {
    setTransactionsData: (state, payload) => {
        state.transactions = payload;
    },
    setTotalTransactions: (state, payload) => {
        state.totalTransaction = payload;
    },
};

const actions = {
    getTransactionsDataPagination: async ({ commit }, payload) => {
        try {
            const params = {
                ...payload,
            };
            const {
                data: { data },
            } = await axios.get(GET_ALL_USER_TRANSACTIONS_URL, { params });

            commit('setTransactionsData', data.transactions);
            commit('setTotalTransactions', data.count);
        } catch (error) {
            console.log(error);
            commit('setTotalTransactions', 0);
            commit('setTransactionsData', []);
        }
    },

    getPdfTransactionInvoice: async ({}, payload) => {
        try {
            const { data } = await axios({
                method: 'GET',
                url: GET_PDF_INVOICE_TRANSACTIONS_URL + `?${payload}`,
                responseType: 'blob',
            });
            return data;
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    getPdfTransactionDetails: async ({}, payload) => {
        try {
            const { data } = await axios({
                method: 'GET',
                url: GET_PDF_DETAILS_TRANSACTIONS_URL + `?${payload}`,
                responseType: 'blob',
            });
            return data;
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    getAllTransactionExcel: async ({}, payload) => {
        try {
            const { data } = await axios({
                method: 'GET',
                url: GET_ALL_TRANSACTION_EXCEL,
                responseType: 'blob',
            });
            return data;
        } catch (error) {
            console.log(error);
            return false;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
