import axios from 'axios';
import config from '@/vue.config';
import { makeFormData } from '@/plugins/helper';

const ADD_CORPORATIVE_DOCUMENTS =
    config.store.add_documents.addCorporativeDocument;
const UPDATE_CORPORATIVE_DOCUMENTS =
    config.store.add_documents.updateCorporativeDocument;
const DELETE_CORPORATIVE_DOCUMENTS =
    config.store.add_documents.deleteCorporativeDocument;
const GET_ALL_DOCUMENTS_CATEGORIES =
    config.store.add_documents.getAllDocumentsCategories;
const GET_ALL_CORPORATIVE_DOCUMENTS =
    config.store.add_documents.getAllCorporativeDocuments;

const state = {
    categories_documents: [],
    corporative_documents: [],
    countCorporativeDocuments: 0,
};

const getters = {
    getAllCorporativeDocuments: state => {
        return state.corporative_documents;
    },

    getCountCorporativeDocuments: state => {
        return state.countCorporativeDocuments;
    },

    getCategoriesDocuments: state => {
        return state.categories_documents;
    },
};

const mutations = {
    setAllCorporativeDocuments: (state, payload) => {
        state.corporative_documents = payload;
    },

    setCountCorporativeDocuments: (state, payload) => {
        state.countCorporativeDocuments = payload;
    },

    setCategoriesDocuments: (state, payload) => {
        state.categories_documents = payload;
    },
};

const actions = {
    addCorporativeDocument: async ({}, payload) => {
        try {
            let formData = { ...payload, type: 1 };
            formData = makeFormData(formData);
            await axios.post(ADD_CORPORATIVE_DOCUMENTS, formData);
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    },

    updateCorporativeDocument: async ({}, payload) => {
        try {
            let formData = { ...payload };
            formData = makeFormData(formData);
            await axios.post(UPDATE_CORPORATIVE_DOCUMENTS, formData);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    deleteCorporativeDocument: async ({}, payload) => {
        try {
            let formData = { ...payload };
            formData = makeFormData(formData);
            await axios.post(DELETE_CORPORATIVE_DOCUMENTS, formData);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    fetchAllDocumentsCategories: async ({ commit }) => {
        try {
            const {
                data: { data },
            } = await axios.get(GET_ALL_DOCUMENTS_CATEGORIES);
            commit('setCategoriesDocuments', data);
        } catch (error) {
            console.error(error);
            commit('setCategoriesDocuments', []);
        }
    },

    fetchAllCorporativeDocuments: async ({ commit }, payload) => {
        try {
            const params = {
                ...payload,
            };

            const {
                data: { data },
            } = await axios.get(GET_ALL_CORPORATIVE_DOCUMENTS, { params });
            commit('setAllCorporativeDocuments', data.documents);
            commit('setCountCorporativeDocuments', data.count);
        } catch (error) {
            console.error(error);
            commit('setAllCorporativeDocuments', []);
            commit('setCountCorporativeDocuments', 0);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
