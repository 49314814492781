import axios from 'axios';
import config from '@/vue.config';

const GET_RESERVED_URL = config.store.reserved.getReserved;
const APPROVE_RESERVED_URL = config.store.reserved.approveReserved;
const DECLINE_RESERVED_URL = config.store.reserved.declineReserved;
const GET_ALL_RESERVED_URL = config.store.reserved.getAllReservedExcel;

const state = {
    reserved: [],
};

const getters = {
    getReserved: state => {
        return state.reserved.sort((a, b) => b.time - a.time);
    },
};

const mutations = {
    setReserved: (state, payload) => {
        state.reserved = payload;
    },
};

const actions = {
    fetchReserved: async ({ commit }) => {
        try {
            const {
                data: { data },
            } = await axios.get(GET_RESERVED_URL);
            commit('setReserved', data);
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    postApproveReserved: async ({}, payload) => {
        try {
            await axios.post(APPROVE_RESERVED_URL, payload);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    postDeclineReserved: async ({}, payload) => {
        try {
            await axios.post(DECLINE_RESERVED_URL, payload);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    getAllReservedExcel: async ({}, payload) => {
        try {
            const { data } = await axios({
                method: 'GET',
                url: GET_ALL_RESERVED_URL + '?startup_id=1',
                responseType: 'blob',
            });
            return data;
        } catch (error) {
            console.log(error);
            return false;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
