import axios from 'axios';
import config from '@/vue.config';

const GET_STARTUP_URL = config.store.startup.getStartup;
const GET_STARTUP_STATUS_URL = config.store.startup.getStartupStatus;

const state = {
    startups: [],
    startup_status: [],
};

const getters = {
    getStartups: state => {
        return Object.values(state.startups);
    },
    getStartupsStatus: state => {
        return Object.values(state.startup_status);
    },
};

const mutations = {
    setStartups: (state, payload) => {
        state.startups = payload;
    },
    setStartupsStatus: (state, payload) => {
        state.startup_status = payload;
    },
};

const actions = {
    fetchStartup: async ({ commit }, payload) => {
        await axios
            .get(GET_STARTUP_URL, { params: payload })
            .then(({ data }) => {
                commit('setStartups', data.data);
            })
            .catch(err => commit('setStartups', []));
    },
    fetchStartupStatus: async ({ commit }, payload) => {
        await axios
            .get(GET_STARTUP_STATUS_URL, { params: payload })
            .then(({ data }) => {
                commit('setStartupsStatus', data.data);
            })
            .catch(err => commit('setStartupsStatus', []));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
