import Vue from 'vue';
import Vuex from 'vuex';

import users from './modules/users/users';
import startups from './modules/startups/startups';
import user_modal from '@/store/modules/users/user_modal';
import startup_modal from '@/store/modules/startups/startup_modal';
import transactions from '@/store/modules/transactions/transactions';
import reserved from '@/store/modules/reserved/reserved';
import commissions from '@/store/modules/commissions/commissions';
import paperShares from '@/store/modules/paperShares/paperShares';
import packagesShares from '@/store/modules/packagesShares/packagesShares';
import shareholders from '@/store/modules/shareholders/shareholders';
import salesManager from '@/store/modules/salesManager/salesManager';
import addDocuments from '@/store/modules/addDocuments/addDocuments';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
        barImage:
            'https://demos.creative-tim.com/vue-material-dashboard/img/sidebar-1.23832d31.jpg',
        drawer: null,
    },
    mutations: {
        SET_BAR_IMAGE(state, payload) {
            state.barImage = payload;
            Vue.cookie.set('image_bg_nav', payload);
        },
        SET_DRAWER(state, payload) {
            state.drawer = payload;
        },
    },
    modules: {
        users,
        user_modal,
        startups,
        startup_modal,
        transactions,
        reserved,
        commissions,
        paperShares,
        packagesShares,
        shareholders,
        salesManager,
        addDocuments,
    },
});
