import Vue from 'vue';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import {
    email,
    max,
    min,
    numeric,
    required,
    digits,
    alpha_spaces,
} from 'vee-validate/dist/rules';

extend('email', email);

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
});

extend('min', {
    ...min,
    message: '{_field_} min length {length} characters',
});

extend('numeric', numeric);

extend('required', {
    ...required,
    message: '{_field_} can not be empty',
});

extend('alpha_spaces', {
    ...alpha_spaces,
    message: '{_field_} can contain only alphabetic characters and spaces',
});

extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
});

extend('title_documents', {
    validate(value) {
        if (value) {
            return /^[a-zA-Z0-9_"\'\/(),.#№’“ ”(\r\n)\-]+$/.test(value);
        }
        return false;
    },
});

Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
