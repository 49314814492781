export function makeFormData(formValues) {
    const formData = new FormData();
    for (let [key, value] of Object.entries(formValues)) {
        formData.append(key, value);
    }
    return formData;
}

export function makeFormJsonData(formValues) {
    const formData = new FormData();
    for (let [key, value] of Object.entries(formValues)) {
        formData.append(key, JSON.stringify(value));
    }
    return formData;
}

export function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

export function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

export function sortDateRange(dateRangeArray) {
    let result;
    if (dateRangeArray.length === 2) {
        let diff = new Date(dateRangeArray[0]) - new Date(dateRangeArray[1]);
        if (diff <= 0) {
            result = {
                date_start: dateRangeArray[0],
                date_end: dateRangeArray[1],
            };
        } else {
            result = {
                date_start: dateRangeArray[1],
                date_end: dateRangeArray[0],
            };
        }
    } else {
        result = { date_start: dateRangeArray[0], date_end: dateRangeArray[0] };
    }
    return result;
}

export function toTimestamp(strDate) {
    let datum = Date.parse(strDate);
    return datum / 1000;
}

export function getDateHumanFormat(daysAgo = 0) {
    let date = new Date();
    date.setDate(date.getDate() - daysAgo);
    let dateString = date.toISOString().split('T')[0];
    return dateString;
}

export default {
    makeFormData,
    getBase64,
    dataURLtoFile,
    sortDateRange,
    toTimestamp,
    getDateHumanFormat,
};
