import axios from 'axios';
import config from '@/vue.config';

const GET_PAPER_SHARES_URL = config.store.paper_shares.getPaperShares;
const GET_PAPER_CERTIFICATE_URL = config.store.paper_shares.getPaperCertificate;
const GET_ALL_CERTIFICATE_URL =
    config.store.paper_shares.getAllCertificatesExcel;

const state = {
    paperShares: [],
    countPaperShares: 0,
};

const getters = {
    getPaperShares: state => {
        return state.paperShares;
    },
    getCountPaperShares: state => {
        return state.countPaperShares;
    },
};

const mutations = {
    setPaperShares: (state, payload) => {
        state.paperShares = payload;
    },
    setCountPaperShares: (state, payload) => {
        state.countPaperShares = payload;
    },
};

const actions = {
    fetchPaperShares: async ({ commit }, payload) => {
        try {
            const params = {
                ...payload,
            };
            const {
                data: { data },
            } = await axios.get(GET_PAPER_SHARES_URL, { params });
            commit('setPaperShares', data.data);
            commit('setCountPaperShares', data.count);
        } catch (error) {
            console.error(error);
            commit('setPaperShares', []);
            commit('setCountPaperShares', 0);
        }
    },

    getPaperShareCertificate: async ({}, payload) => {
        try {
            const params = { ...payload };

            const { data } = await axios.get(GET_PAPER_CERTIFICATE_URL, {
                params,
                responseType: 'blob',
            });
            return data;
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    getAllCertificatesExcel: async ({}, payload) => {
        try {
            const { data } = await axios({
                method: 'GET',
                url: GET_ALL_CERTIFICATE_URL,
                responseType: 'blob',
            });
            return data;
        } catch (error) {
            console.log(error);
            return false;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
