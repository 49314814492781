import axios from 'axios';
import config from '@/vue.config';

const GET_USERS_URL = config.store.user.getUser;
const GET_STATUSES_URL = config.store.user.getUserStatuses;
const GET_USERS_DASHBOARD = config.store.user.getUsersGroupBy;
const GET_USER_COUNTRIES = config.store.user.getUserCountries;
const GET_USER_IDENTIFICATION_TYPES =
    config.store.user.getUserIdentificationTypes;
const GET_ALL_USERS_URL = config.store.user.getAllUsersExcel;

const state = {
    users: [],
    userStatuses: [],
    userDashboard: [],
    userCountries: [],
    userIdentificationTypes: [],
};

const getters = {
    getUsers: state => {
        return Object.values(state.users).reverse();
    },
    getUserStatuses: state => {
        return Object.values(state.userStatuses);
    },
    getUserDashboard: state => {
        return state.userDashboard;
    },
    getUserCountries: state => {
        return state.userCountries;
    },
    getUserIdentificationTypes: state => {
        return Object.values(state.userIdentificationTypes);
    },
};

const mutations = {
    setUsers: (state, payload) => {
        state.users = payload;
    },
    setUserStatuses: (state, payload) => {
        state.userStatuses = payload;
    },
    setUserDashboard: (state, payload) => {
        state.userDashboard = payload;
    },
    setUserCountries: (state, payload) => {
        state.userCountries = payload;
    },
    setUserIdentificationTypes: (state, payload) => {
        state.userIdentificationTypes = payload;
    },
};

const actions = {
    fetchUsers: async ({ commit }, payload) => {
        await axios
            .get(GET_USERS_URL, { params: payload })
            .then(({ data }) => {
                commit('setUsers', data.data);
            })
            .catch(err => commit('setUsers', []));
    },
    fetchUserStatuses: async ({ commit }) => {
        await axios
            .get(GET_STATUSES_URL)
            .then(({ data }) => {
                commit('setUserStatuses', data);
            })
            .catch(err => commit('setUserStatuses', []));
    },
    fetchUsersDashboard: async ({ commit }, payload) => {
        await axios
            .get(GET_USERS_DASHBOARD, { params: payload })
            .then(({ data }) => {
                commit('setUserDashboard', data.data);
            })
            .catch(err => commit('setUserDashboard', []));
    },
    fetchUserCountries: async ({ commit }) => {
        await axios
            .get(GET_USER_COUNTRIES)
            .then(({ data }) => {
                commit('setUserCountries', data);
            })
            .catch(err => commit('setUserStatuses', []));
    },
    fetchIdentificationTypes: async ({ commit }) => {
        await axios
            .get(GET_USER_IDENTIFICATION_TYPES)
            .then(({ data }) => {
                commit('setUserIdentificationTypes', data.data);
            })
            .catch(err => {
                commit('setUserIdentificationTypes', []);
            });
    },
    getAllUsersExcel: async ({}, payload) => {
        try {
            const { data } = await axios({
                method: 'GET',
                url: GET_ALL_USERS_URL,
                responseType: 'blob',
            });
            return data;
        } catch (error) {
            console.log(error);
            return false;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
