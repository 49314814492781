import axios from 'axios';
import config from '@/vue.config';
import { makeFormData } from '@/plugins/helper';

const ADD_SALES_MANAGER = config.store.sales_manager.addSalesManager;
const GET_SALES_MANAGERS = config.store.sales_manager.getSalesManagers;
const GET_MANAGER_USERS = config.store.sales_manager.getManagerUsers;

const state = {
    sales_managers: [],
    manager_users: [],
};

const getters = {
    getSalesManagers: state => {
        return state.sales_managers.sort((a, b) => b.created_at - a.created_at);
    },
    getManagerUsers: state => {
        return state.manager_users;
    },
};

const mutations = {
    setSalesManagers: (state, payload) => {
        state.sales_managers = payload;
    },
    setManagerUsers: (state, payload) => {
        state.manager_users = payload;
    },
};

const actions = {
    fetchSalesManagers: async ({ commit }) => {
        try {
            const {
                data: { data },
            } = await axios.get(GET_SALES_MANAGERS);
            commit('setSalesManagers', data.managers);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    fetchManagerUsers: async ({ commit }, payload) => {
        try {
            const params = { sales_id: payload };
            const {
                data: { data },
            } = await axios.get(GET_MANAGER_USERS, { params });
            commit('setManagerUsers', data.users);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    addSalesManager: async ({}, payload) => {
        try {
            let formData = { ...payload };
            formData = makeFormData(formData);
            const {
                data: { data },
            } = await axios.post(ADD_SALES_MANAGER, formData);
            return data.code;
        } catch (error) {
            console.log(error);
            return false;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
