import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueAlertify from "vue-alertify";
import VueCookie from "vue-cookie";
import axios from 'axios'
import VueAxios from 'vue-axios'
import HighchartsVue from 'highcharts-vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import config from '@/vue.config';
import Vue2Editor from "vue2-editor";
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import i18n from './i18n'

Vue.config.productionTip = false

Vue.prototype.$config = config;

Vue.use(Antd)
Vue.use(VueAlertify)
Vue.use(VueCookie)
Vue.use(VueAxios,axios)
Vue.use(HighchartsVue)
Vue.use(Vue2Editor);


new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
}).$mount('#app')
