import axios from 'axios';
import config from '@/vue.config';
import { makeFormData } from '@/plugins/helper';

const GET_PACKAGES_SHARES_URL = config.store.packages_shares.getPackagesShares;
const CREATE_PACKAGE_SHARES_URL = config.store.packages_shares.createPackage;
const CHANGE_PACKAGE_SHARES_URL = config.store.packages_shares.changePackage;
const DELETE_PACKAGE_SHARES_URL = config.store.packages_shares.deletePackage;

const state = {
    packagesShares: [],
};

const getters = {
    getPackagesShares: state => {
        return state.packagesShares.sort((a, b) => b.id - a.id);
    },
};

const mutations = {
    setPackagesShares: (state, payload) => {
        state.packagesShares = payload;
    },
};

const actions = {
    getAllPackagesShares: async ({ commit }) => {
        try {
            const {
                data: { data },
            } = await axios.get(GET_PACKAGES_SHARES_URL);
            commit('setPackagesShares', data);
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    createPackage: async ({}, payload) => {
        let formData = makeFormData(payload);
        return await axios.post(CREATE_PACKAGE_SHARES_URL, formData);
    },

    changePackage: async ({}, payload) => {
        let formData = makeFormData(payload);
        return await axios.post(CHANGE_PACKAGE_SHARES_URL, formData);
    },

    deletePackage: async ({}, payload) => {
        try {
            let formData = makeFormData(payload);
            await axios.post(DELETE_PACKAGE_SHARES_URL, formData);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
