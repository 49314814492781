import Vue from 'vue';
import Router from 'vue-router';
import axios from 'axios';
import config from '@/vue.config';

Vue.use(Router);
const routes = [
    {
        path: '/',
        name: 'login',
        meta: { layout: 'login' },
        component: () => import('@/views/dashboard/pages/Login'),
    },
    {
        name: 'Dashboard',
        path: '/pages/dashboard',
        meta: { layout: 'index' },
        component: () => import('@/views/dashboard/Dashboard'),
    },
    {
        name: 'Users',
        path: '/pages/users',
        meta: { layout: 'index' },
        component: () => import('@/views/dashboard/pages/Users'),
    },
    // {
    //     name: 'Projects',
    //     path: '/pages/projects',
    //     meta: { layout: 'index' },
    //     component: () => import('@/views/dashboard/pages/Startups'),
    // },
    {
        name: 'Transactions',
        path: '/pages/transactions',
        meta: { layout: 'index' },
        component: () => import('@/views/dashboard/pages/Transactions'),
    },
    {
        name: 'Reserved Shares',
        path: '/pages/reserved',
        meta: { layout: 'index' },
        component: () => import('@/views/dashboard/pages/Reserved'),
    },
    {
        name: 'Commissions',
        path: '/pages/commissions',
        meta: { layout: 'index' },
        component: () => import('@/views/dashboard/pages/Commissions'),
    },
    {
        name: 'Paper Certificates',
        path: '/pages/certificate',
        meta: { layout: 'index' },
        component: () => import('@/views/dashboard/pages/PaperShares'),
    },
    // {
    //     name: 'Packages of Shares',
    //     path: '/pages/packages-shares',
    //     meta: { layout: 'index' },
    //     component: () => import('@/views/dashboard/pages/PackagesShares'),
    // },
    {
        name: 'Shareholders',
        path: '/pages/shareholders',
        meta: { layout: 'index' },
        component: () => import('@/views/dashboard/pages/Shareholders'),
    },
    {
        name: 'Sales Manager',
        path: '/pages/sales-manager',
        meta: { layout: 'index' },
        component: () => import('@/views/dashboard/pages/SalesManager'),
    },
    {
        name: 'Add Documents',
        path: '/pages/add-documents',
        meta: { layout: 'index' },
        component: () => import('@/views/dashboard/pages/AddDocuments'),
    },
];
const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});
router.beforeEach((to, from, next) => {
    if (to.name !== 'login') {
        let token = Vue.cookie.get('auth_token_admin');
        if (token === null) next({ name: 'login' });
        axios
            .get(config.store.auth.identification, {
                headers: { Authorization: token },
            })
            .then(response => {
                // next
            })
            .catch(error => {
                next({ name: 'login' });
                Vue.cookie.delete('auth_token_admin');
            });
    }
    next();
});
export default router;
