<script>
import Login from './views/dashboard/Login';
import Index from './views/dashboard/Index';

export default {
    name: 'App',
    computed: {
        layout() {
            return this.$route.meta.layout || 'login';
        },
    },
    components: {
        Login,
        Index,
    },
    beforeCreate() {
        this.axios.defaults.headers.common['Authorization'] = this.$cookie.get(
            'auth_token_admin',
        );
    },
};
</script>
<template>
    <component :is="layout">
        <router-view />
    </component>
</template>

<style>
.v-sheet.v-footer {
    position: fixed;
    background-color: #fff !important;
    bottom: 0;
}

.v-main__wrap > div {
    padding-bottom: 100px;
}
</style>
