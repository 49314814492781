import axios from 'axios';
import config from '@/vue.config';
import { makeFormData } from '@/plugins/helper';

const GET_USER_INFO_URL = config.store.user.getUserInfo;
const EDIT_PROFILE_URL = config.store.user.editProfile;
const CHANGE_USER_STATUS_URL = config.store.user.changeUserStatus;
const RESET_USER_FINANCES = config.store.user.resetFinances;

const state = {
    user_info: [],
    user_modal_show: false,
    user_info_errors: [],
};

const getters = {
    getUserInfo: state => {
        return state.user_info;
    },
    getModalShow: state => {
        return state.user_modal_show;
    },
    getUserInfoErrors: state => {
        return state.user_info_errors;
    },
};

const mutations = {
    setUserInfo: (state, payload) => {
        state.user_info = payload;
    },
    openModal: state => {
        state.user_modal_show = true;
    },
    closeModal: state => {
        state.user_modal_show = false;
    },
    setUserInfoErrors: (state, payload) => {
        state.user_info_errors = payload;
    },
};

const actions = {
    fetchUserInfo: async ({ commit }, user_id) => {
        await axios
            .get(GET_USER_INFO_URL, { params: { user_id } })
            .then(({ data }) => {
                commit('setUserInfo', data.data[user_id]);
                commit('openModal');
            })
            .catch(err => {
                commit('setUserInfo', []);
                commit('closeModal');
            });
    },
    closeModal: ({ commit }) => {
        commit('closeModal');
    },
    openModal: ({ commit }) => {
        commit('openModal');
    },
    fetchEditProfile: async ({ commit, rootState }, data) => {
        let formData = makeFormData(data);
        return await axios
            .post(EDIT_PROFILE_URL, formData)
            .then(async result => {
                commit(
                    'users/setUsers',
                    {
                        ...rootState.users.users,
                        ...{ [data.user_id]: result.data.data[data.user_id] },
                    },
                    { root: true },
                );
                commit('setUserInfoErrors', []);
            })
            .catch(err => {
                commit('setUserInfoErrors', err.response.data.data);
                return err.response.data.data;
            });
    },
    fetchApproveUser: async ({ commit, rootState, dispatch, state }, data) => {
        let formData = makeFormData(data);
        return await axios
            .post(CHANGE_USER_STATUS_URL, formData)
            .then(async result => {
                await dispatch('fetchUserInfo', data.user_id);
                commit(
                    'users/setUsers',
                    {
                        ...rootState.users.users,
                        ...{ [data.user_id]: state.user_info },
                    },
                    { root: true },
                );
                return true;
            })
            .catch(err => {
                return false;
            });
    },
    fetchDeclineUser: async ({ commit, rootState, dispatch, state }, data) => {
        let formData = makeFormData(data);
        return await axios
            .post(CHANGE_USER_STATUS_URL, formData)
            .then(async result => {
                await dispatch('fetchUserInfo', data.user_id);
                commit(
                    'users/setUsers',
                    {
                        ...rootState.users.users,
                        ...{ [data.user_id]: state.user_info },
                    },
                    { root: true },
                );
                return true;
            })
            .catch(err => {
                return false;
            });
    },
    resetFinances: async ({ commit, rootState, dispatch, state }, data) => {
        return await axios
            .get(RESET_USER_FINANCES, { params: data })
            .then(async () => {
                await dispatch('fetchUserInfo', data.user_id);
                commit(
                    'users/setUsers',
                    {
                        ...rootState.users.users,
                        ...{ [data.user_id]: state.user_info },
                    },
                    { root: true },
                );
                return true;
            })
            .catch(err => {
                return false;
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
