import axios from 'axios';
import config from '@/vue.config';
import { makeFormData } from '@/plugins/helper';

const GET_ALL_DISCOUNTS = config.store.commissions.getAllDiscounts;
const GET_COMMISSIONS_URL = config.store.commissions.getCommissionsPayment;
const CHANGE_PAYMENT_FEE_URL = config.store.commissions.changePaymentFee;
const CREATE_DISCOUNT = config.store.commissions.createDiscount;

const state = {
    commissions: {},
    all_discount: [],
};

const getters = {
    getCommissionsBuyShares: state => state.commissions,
    getAllDiscount: state => state.all_discount,
};

const mutations = {
    setCommissionsBuyShares: (state, payload) => {
        state.commissions = payload;
    },
    setAllDiscount: (state, payload) => {
        state.all_discount = payload;
    },
};

const actions = {
    fetchGetAllDiscounts: async ({ commit }) => {
        try {
            const {
                data: { data },
            } = await axios.get(GET_ALL_DISCOUNTS);
            commit('setAllDiscount', data.discounts);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    fetchGetCommissions: async ({ commit }) => {
        try {
            const {
                data: { data },
            } = await axios.get(GET_COMMISSIONS_URL);
            commit('setCommissionsBuyShares', data);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    postChangePaymentFee: async ({}, payload) => {
        if (payload) {
            try {
                await axios.post(CHANGE_PAYMENT_FEE_URL, payload);
                return true;
            } catch (error) {
                console.log(error);
                return false;
            }
        }
    },

    postCreateDiscount: async ({}, payload) => {
        if (payload) {
            try {
                let formData = makeFormData(payload);
                const { data } = await axios.post(CREATE_DISCOUNT, formData);
                return data.data;
            } catch (error) {
                console.log(error);
                return false;
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
