import axios from 'axios';
import config from '@/vue.config';

const GET_SHAREHOLDERS = config.store.shareholders.getShareholders;
const GET_ALL_SHAREHOLDERS_EXCEL =
    config.store.shareholders.getAllShareholdersExcel;
const GET_SHAREHOLDER_SHARES = config.store.shareholders.getShareholderShares;

const state = {
    shareholders: [],
    shareholder_shares: [],
};

const getters = {
    getShareholders: state => {
        return state.shareholders;
    },
    getShareholderShares: state => {
        return state.shareholder_shares;
    },
};

const mutations = {
    setShareholders: (state, payload) => {
        state.shareholders = payload;
    },
    setShareholderShares: (state, payload) => {
        state.shareholder_shares = payload;
    },
};

const actions = {
    fetchShareholders: async ({ commit }) => {
        try {
            const {
                data: { data },
            } = await axios.get(GET_SHAREHOLDERS);
            commit('setShareholders', data.shareholders);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    fetchShareholderShares: async ({ commit }, payload) => {
        try {
            const params = { user_id: payload };
            const {
                data: { data },
            } = await axios.get(GET_SHAREHOLDER_SHARES, { params });
            commit('setShareholderShares', data.shareholders_shares);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    },

    getAllShareholdersExcel: async () => {
        try {
            const { data } = await axios({
                method: 'GET',
                url: GET_ALL_SHAREHOLDERS_EXCEL,
                responseType: 'blob',
            });
            return data;
        } catch (error) {
            console.log(error);
            return false;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
