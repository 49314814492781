import axios from 'axios';
import config from '@/vue.config';

const GET_STARTUP_INFO_URL = config.store.startup.getStartupPlacement;
const STARTUP_APPROVE_URL = config.store.startup.startupApprove;
const STARTUP_DECLINE_URL = config.store.startup.startupDecline;
const GET_USER_COUNTRIES = config.store.user.getUserCountries;
const GET_INDUSTRIES = config.store.startup.getIndustries;

const state = {
    startup_info: [],
    startup_modal_show: false,
    startupCountries: [],
    startupIndustries: [],
};

const getters = {
    getStartupInfo: state => {
        return state.startup_info;
    },
    getModalShow: state => {
        return state.startup_modal_show;
    },
    getStartupCountries: state => {
        return state.startupCountries;
    },
    getStartupIndustries: state => {
        return state.startupIndustries;
    },
};

const mutations = {
    setStartupInfo: (state, payload) => {
        state.startup_info = payload;
    },
    openModal: state => {
        state.startup_modal_show = true;
    },
    closeModal: state => {
        state.startup_modal_show = false;
    },
    setStartupCountries: (state, payload) => {
        state.startupCountries = payload;
    },
    setStartupIndustries: (state, payload) => {
        state.startupIndustries = payload;
    },
};

const actions = {
    fetchStartupInfo: async ({ commit }, startup_id) => {
        await axios
            .get(GET_STARTUP_INFO_URL, { params: { id: startup_id } })
            .then(({ data }) => {
                commit('setStartupInfo', data.data[0]);
                commit('openModal');
            })
            .catch(err => {
                commit('setStartupInfo', []);
                commit('closeModal');
            });
    },

    fetchStartupCountries: async ({ commit }, payload) => {
        await axios
            .get(GET_USER_COUNTRIES, payload)
            .then(({ data }) => {
                commit('setStartupCountries', data);
            })
            .catch(err => commit('setStartupCountries', []));
    },

    fetchStartupIndustries: async ({ commit }, payload) => {
        await axios
            .get(GET_INDUSTRIES, payload)
            .then(({ data }) => {
                commit('setStartupIndustries', data.data);
            })
            .catch(err => commit('setStartupIndustries', []));
    },

    closeModal: ({ commit }) => {
        commit('closeModal');
    },

    openModal: ({ commit }) => {
        commit('openModal');
    },

    fetchApproveStartup: async ({ commit }, startup_id) => {
        return await axios.get(STARTUP_APPROVE_URL, { params: { startup_id } });
    },

    fetchDeclineStartup: async ({ commit }, data) => {
        return await axios
            .get(STARTUP_DECLINE_URL, { params: data })
            .then(({ data }) => {
                return true;
            })
            .catch(err => {
                return false;
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
